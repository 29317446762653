import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { getLanguageName } from '../../lib/get-language-name';
import * as Styled from './languages.styles';

interface IProps {
    onChange: (locale: string) => void;
}

export const Languages = ({ onChange }: IProps) => {
    const { locale: activeLocale, locales, asPath } = useRouter();
    return (
        <Styled.Nav>
            {locales?.map((locale) => {
                const link = (
                    <Styled.LocaleLink onClick={() => onChange(locale)}>{getLanguageName(locale)}</Styled.LocaleLink>
                );
                return locale === activeLocale ? (
                    <Fragment key={locale}>{link}</Fragment>
                ) : (
                    <Link key={locale} href={asPath} locale={locale} passHref>
                        {link}
                    </Link>
                );
            })}
        </Styled.Nav>
    );
};
