import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useBoolean, useEventListener } from 'usehooks-ts';
import { CloseButton, MobileDrawer } from 'common/ui';
import { Languages } from '../languages/languages';
import * as Styled from './language-switcher.styles';

interface IProps {
    children: React.ReactElement;
    onChange?: (locale: string) => void;
    onClose?: () => void;
}

export const MobileLanguageSwitcher = ({ children, onChange, onClose }: IProps) => {
    const { t } = useTranslation();
    const visibility = useBoolean();

    const handleChange = async (nextLocale: string) => {
        visibility.setFalse();
        onChange?.(nextLocale);
    };

    const handleClose = async () => {
        visibility.setFalse();
        onClose?.();
    };

    const anchorRef = useRef<HTMLButtonElement>(null);

    useEventListener('click', visibility.toggle, anchorRef);

    return (
        <>
            {React.cloneElement(React.Children.only(children), { ref: anchorRef })}
            <MobileDrawer isOpen={visibility.value} onClose={visibility.setFalse}>
                <Styled.Wrapper>
                    <Styled.Header>
                        <Styled.Heading>{t('language_switcher_choose_language_text')}</Styled.Heading>
                        <CloseButton onClick={handleClose} />
                    </Styled.Header>
                    <Languages onChange={handleChange} />
                </Styled.Wrapper>
            </MobileDrawer>
        </>
    );
};
