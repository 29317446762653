import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useBoolean, useEventListener } from 'usehooks-ts';
import { DropList, IDropListProps } from 'common/ui';
import { Languages } from '../languages/languages';
import * as Styled from './language-switcher.styles';

interface IProps extends Pick<IDropListProps, 'dir' | 'offset' | 'orientation'> {
    onChange?: (locale: string) => void;
    children: React.ReactElement;
}

const defaultOffset = { mainAxis: 8, crossAxis: 15 };

export const DesktopLanguageSwitcher = ({
    dir,
    orientation = 'bottom-end',
    offset = defaultOffset,
    children,
    onChange,
}: IProps) => {
    const { t } = useTranslation();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const visibility = useBoolean();
    useEventListener('click', visibility.toggle, anchorRef);

    const languageChangeHandler = async (nextLocale: string) => {
        visibility.setFalse();
        onChange?.(nextLocale);
    };

    return (
        <>
            {React.cloneElement(React.Children.only(children), { ref: anchorRef })}
            <DropList
                dir={dir}
                anchorRef={anchorRef}
                opened={visibility.value}
                orientation={orientation}
                offset={offset}
                width='17.5rem'
                withoutPadding
                onClickOutside={visibility.setFalse}>
                <Styled.Wrapper>
                    <Styled.Heading>{t('language_switcher_choose_language_text')}</Styled.Heading>
                    <Languages onChange={languageChangeHandler} />
                </Styled.Wrapper>
            </DropList>
        </>
    );
};
