import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Exit } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ROUTES } from 'common/constants';
import { generatePath, useBreakpoints } from 'common/lib';
import { removeAccessToken } from 'common/lib/auth';
import { HeaderButton } from 'common/ui';
import { usePageCity } from 'entities/city';

interface IProps {
    desktopMode?: boolean;
}

const AUTH_ROUTES = [ROUTES.AUTH, ROUTES.SIGNUP];

export const LogoutButton = ({ desktopMode }: IProps) => {
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const router = useRouter();
    const media = useBreakpoints();
    const currentCity = usePageCity();

    const handleUserLogout = React.useCallback(() => {
        removeAccessToken();
        void router
            .push({ pathname: generatePath(ROUTES.CITY, { citySlug: currentCity.citySlug }) })
            .then(() => router.reload());
    }, [currentCity.citySlug, router]);

    if (AUTH_ROUTES.includes(router.pathname)) {
        return null;
    }

    return !media.isGtS || desktopMode ? (
        <Cell
            dir={dir}
            size='s'
            prefix={<Exit dir={dir} size={24} color={variables['text-and-icon-secondary']} />}
            onClick={handleUserLogout}>
            {t('header_logout_button_title')}
        </Cell>
    ) : (
        <HeaderButton dir={dir} prefix={<Exit dir={dir} size={20} />} onClick={handleUserLogout}>
            {t('header_logout_button_title')}
        </HeaderButton>
    );
};
