import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useBoolean } from 'usehooks-ts';
import { BurgerMenu, Close, Earth, Masters, PinOutline } from '@indriver/mireska';
import { Cell, Divider } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { attemptOpenInNewTab, recordEvent, useAuthorizedUser, usePassUtmParams, formatLocaleName } from 'common/lib';
import { getLandingLink } from 'common/lib/get-landing-link';
import { DropList, HeaderButton } from 'common/ui';
import { usePageCity } from 'entities/city';
import { NotificationWhatsApp, useIsWhatsAppEnabled } from 'entities/notification-whatsapp';
import { useUserLocaleMutation } from 'entities/user';
import { CitySwitcher } from 'features/city-switcher';
import { GeoConfirmDialog } from 'features/geo-confirm-dialog';
import { MobileLanguageSwitcher } from 'features/language-switcher';
import { getLanguageName } from 'features/language-switcher/lib/get-language-name';
import { LoginButton } from 'features/login-button';
import { LogoutButton } from 'features/logout-button';
import * as Styled from './mobile-header-menu.styles';

interface IProps {
    cityName: string;
    shouldShowGeoButton: boolean;
    shouldShowLanguageSwitcher: boolean;
    shouldShowWhatsAppNotifications: boolean;
    isGeoConfirmDialogOpen: boolean;
    shouldShowBecomeSpecialistButton?: boolean;
    id: string;
    isDesktopMode?: boolean;
    extraItems?: string[];
}

export const MobileHeaderMenu = ({
    cityName,
    shouldShowGeoButton,
    shouldShowLanguageSwitcher,
    shouldShowWhatsAppNotifications,
    shouldShowBecomeSpecialistButton,
    isGeoConfirmDialogOpen,
    id,
    isDesktopMode,
    extraItems,
}: IProps) => {
    const { locale = '' } = useRouter();
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const isAuthorizedUser = useAuthorizedUser();

    const pageCity = usePageCity();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const citySwitcherVisibility = useBoolean();
    const dropdownVisibility = useBoolean();

    const notificationsWhatsAppEnable = useIsWhatsAppEnabled(isAuthorizedUser);

    const userLocaleMutation = useUserLocaleMutation();
    const handleLanguageChange = async (nextLocale: string) => {
        dropdownVisibility.setFalse();
        if (isAuthorizedUser) {
            await userLocaleMutation.mutateAsync(nextLocale);
        }
    };

    const landingUrl = usePassUtmParams(getLandingLink(pageCity.countryIso));

    const handleButtonClick = () => {
        attemptOpenInNewTab({ url: landingUrl, focus: true });
        recordEvent({
            actionAF: 'masters_customer_become_specialist_click',
            actionAmplitude: 'masters_customer_become_specialist_click',
            actionGA: 'masters_customer_become_specialist_click',
            payload: {
                city_id: pageCity.id,
                city_name: cityName,
                country_id: pageCity.countryId,
                country_name: pageCity.countryName,
                came_from: 'menu',
            },
        });
    };

    const visibleItems = isDesktopMode
        ? {
              becomeSpecialist: shouldShowBecomeSpecialistButton && extraItems?.includes('become_specialist'),
              geo: shouldShowGeoButton && extraItems?.includes('geo'),
              geoConfirmDialogOpen: false,
              language: shouldShowLanguageSwitcher && extraItems?.includes('language'),
              loginLogout: extraItems?.includes('login_logout'),
              myOrders: false,
              whatsApp: isAuthorizedUser && shouldShowWhatsAppNotifications && extraItems?.includes('whatsApp'),
          }
        : {
              becomeSpecialist: shouldShowBecomeSpecialistButton,
              geo: shouldShowGeoButton,
              geoConfirmDialogOpen: shouldShowGeoButton && isGeoConfirmDialogOpen,
              language: shouldShowLanguageSwitcher,
              loginLogout: true,
              myOrders: isAuthorizedUser,
              whatsApp: isAuthorizedUser && shouldShowWhatsAppNotifications,
          };

    return (
        <>
            <HeaderButton
                ref={anchorRef}
                id={id}
                design='secondary'
                prefix={dropdownVisibility.value ? <Close size={24} /> : <BurgerMenu size={24} />}
                onClick={dropdownVisibility.toggle}
            />
            <DropList
                dir={dir}
                anchorRef={anchorRef}
                opened={dropdownVisibility.value}
                width='18rem'
                orientation='bottom-end'
                offset={{ mainAxis: 8 }}
                zIndex={99}
                withoutPadding>
                <Styled.Box>
                    {visibleItems.myOrders && (
                        <>
                            <Link href='/orders?from=menu'>
                                <Cell dir={dir} size='s'>
                                    {t('header_my_orders_link_title')}
                                </Cell>
                            </Link>
                            <Divider width='auto' style={{ margin: '8px 16px' }} />
                        </>
                    )}

                    {visibleItems.becomeSpecialist && (
                        <>
                            <Cell
                                dir={dir}
                                size='s'
                                prefix={<Masters size={24} color='var(--text-and-icon-secondary)' />}
                                onClick={handleButtonClick}>
                                {t('header_become_a_specialist_button_title')}
                            </Cell>
                            <Divider width='auto' style={{ margin: '8px 16px' }} />
                        </>
                    )}
                    {visibleItems.geo && (
                        <CitySwitcher
                            dir={dir}
                            visibility={citySwitcherVisibility}
                            isMobile
                            onClose={dropdownVisibility.setFalse}
                            onSelect={dropdownVisibility.setFalse}>
                            <Cell
                                dir={dir}
                                size='s'
                                prefix={<PinOutline size={24} color={variables['text-and-icon-secondary']} />}
                                onClick={citySwitcherVisibility.toggle}>
                                {cityName}
                            </Cell>
                        </CitySwitcher>
                    )}
                    {visibleItems.language && (
                        <MobileLanguageSwitcher onClose={dropdownVisibility.setFalse} onChange={handleLanguageChange}>
                            <Cell
                                dir={dir}
                                size='s'
                                prefix={<Earth size={24} color={variables['text-and-icon-secondary']} />}>
                                {formatLocaleName(locale)} ({getLanguageName(locale)})
                            </Cell>
                        </MobileLanguageSwitcher>
                    )}
                    {visibleItems.loginLogout &&
                        (isAuthorizedUser ? (
                            <LogoutButton desktopMode={isDesktopMode} />
                        ) : (
                            <LoginButton desktopMode={isDesktopMode} />
                        ))}
                    {visibleItems.whatsApp && (
                        <>
                            <Divider width='auto' style={{ margin: '8px 16px' }} />
                            <NotificationWhatsApp
                                positionX={{ left: 'auto', right: '0' }}
                                enable={notificationsWhatsAppEnable.data}
                                isOpen
                                desktopMode={isDesktopMode}
                            />
                        </>
                    )}
                </Styled.Box>
            </DropList>
            {visibleItems.geoConfirmDialogOpen ? (
                <GeoConfirmDialog
                    isOpen={isGeoConfirmDialogOpen}
                    onReject={() => {
                        dropdownVisibility.setTrue();
                        citySwitcherVisibility.setTrue();
                    }}
                />
            ) : null}
        </>
    );
};
