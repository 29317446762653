export const getLastVisibleItem = ({
    necessaryWidths,
    containerWidth,
    moreWidth,
}: {
    necessaryWidths: number[];
    containerWidth: number;
    moreWidth: number;
}) => {
    if (!necessaryWidths?.length) {
        return 0;
    }
    if (necessaryWidths[necessaryWidths.length - 1] < containerWidth) {
        return necessaryWidths.length - 1;
    }

    const visibleItems = necessaryWidths.filter((width) => {
        return width + moreWidth < containerWidth;
    });

    return visibleItems.length - 1;
};
