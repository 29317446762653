export const ROUTES = {
    CITY: '/:citySlug',
    CATEGORY: '/:citySlug?/category/:categorySlug',
    SERVICE: '/:citySlug?/service/:serviceSlug',
    SUB_SERVICE: '/:citySlug?/service/:serviceSlug/:subServiceSlug?',
    ORDERS: '/orders',
    ORDER: '/orders/:orderId',
    ORDER_DETAILS: '/orders/:orderId/details',
    ORDER_EDIT: '/orders/:orderId/edit',
    AUTH: '/auth',
    SIGNUP: '/auth/create-account',
    SIGNUP_SUCCESS: '/auth/success',
    ABOUT_US: '/about',
    CONTACTS: '/contacts',
};
