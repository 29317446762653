import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from 'usehooks-ts';
import { Earth, Masters, NotificationOutline, PinOutline } from '@indriver/mireska';
import { RootState } from 'app/store';
import { ROUTES } from 'common/constants';
import {
    attemptOpenInNewTab,
    formatLocaleName,
    generatePath,
    recordEvent,
    useAuthorizedUser,
    useBreakpoints,
    useOnClickOutside,
    usePassUtmParams,
} from 'common/lib';
import { getLandingLink } from 'common/lib/get-landing-link';
import { HeaderButton, Logo } from 'common/ui';
import { usePageCity, useUserCity } from 'entities/city';
import {
    hideNotificationWhatsApp,
    NotificationWhatsApp,
    toggleNotificationWhatsApp,
    useIsWhatsAppEnabled,
} from 'entities/notification-whatsapp';
import { useUserLocaleMutation } from 'entities/user';
import { CitySwitcher } from 'features/city-switcher';
import { GeoConfirmDialog } from 'features/geo-confirm-dialog';
import { DesktopLanguageSwitcher } from 'features/language-switcher';
import { LoginButton } from 'features/login-button';
import { LogoutButton } from 'features/logout-button';
import { MobileHeaderMenu } from 'widgets/mobile-header-menu';
import { HeaderRightWrapper } from './header-right-wrapper';
import * as Styled from './header.styles';

interface IProps {
    useNativeNavigation?: boolean;
    shouldShowGeoButton?: boolean;
    shouldShowLanguageSwitcher?: boolean;
    shouldShowWhatsAppNotifications?: boolean;
    shouldShowBecomeSpecialistButton?: boolean;
    noMargin?: boolean;
}

export const Header = ({
    useNativeNavigation = false,
    shouldShowGeoButton = false,
    shouldShowLanguageSwitcher = true,
    shouldShowWhatsAppNotifications = true,
    shouldShowBecomeSpecialistButton = true,
    noMargin = false,
}: IProps) => {
    const media = useBreakpoints();
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();

    const isAuthorizedUser = useAuthorizedUser();
    const notificationsWhatsAppEnable = useIsWhatsAppEnabled(isAuthorizedUser);

    const userCity = useUserCity();
    const pageCity = usePageCity();

    const isGeoConfirmDialogOpen = userCity?.id !== pageCity.id;
    const isNotificationWhatsAppOpen = useSelector((state: RootState) => state.notificationWhatsApp);

    const dispatch = useDispatch();
    const notificationWhatsAppRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(notificationWhatsAppRef, isNotificationWhatsAppOpen, () => dispatch(hideNotificationWhatsApp()));

    const visibility = useBoolean();

    const userLocaleMutation = useUserLocaleMutation();
    const landingUrl = usePassUtmParams(getLandingLink(pageCity.countryIso));

    const updateUserLang = async (nextLocale: string) => {
        if (isAuthorizedUser) {
            await userLocaleMutation.mutateAsync(nextLocale);
        }
    };

    const handleButtonClick = () => {
        attemptOpenInNewTab({ url: landingUrl, focus: true });
        recordEvent({
            actionAF: 'masters_customer_become_specialist_click',
            actionAmplitude: 'masters_customer_become_specialist_click',
            actionGA: 'masters_customer_become_specialist_click',
            payload: {
                city_id: pageCity.id,
                city_name: pageCity.name,
                country_id: pageCity.countryId,
                country_name: pageCity.countryName,
                came_from: 'menu',
            },
        });
    };

    const mobileHeaderMenu = (
        <MobileHeaderMenu
            id='mobileHeaderMenu'
            cityName={pageCity.name}
            shouldShowGeoButton={shouldShowGeoButton}
            shouldShowLanguageSwitcher={shouldShowLanguageSwitcher}
            shouldShowWhatsAppNotifications={shouldShowWhatsAppNotifications}
            shouldShowBecomeSpecialistButton={shouldShowBecomeSpecialistButton}
            isGeoConfirmDialogOpen={isGeoConfirmDialogOpen}
            isDesktopMode={media.isGtS}
        />
    );

    const handleLogoClick = React.useMemo(
        () =>
            useNativeNavigation
                ? (e: React.MouseEvent<HTMLAnchorElement>) => {
                      if (e.currentTarget.href) {
                          e.preventDefault();
                          window.location.assign(e.currentTarget.href);
                      }
                  }
                : undefined,
        [useNativeNavigation],
    );

    return (
        <Styled.Header noMargin={noMargin}>
            <Styled.HeaderLeftWrapper>
                <Link href={{ pathname: generatePath(ROUTES.CITY, { citySlug: pageCity.citySlug }) }} passHref>
                    <Styled.LogoLink onClick={handleLogoClick}>
                        <Logo locale={i18n.resolvedLanguage} />
                    </Styled.LogoLink>
                </Link>
            </Styled.HeaderLeftWrapper>
            {media.isGtS ? (
                <HeaderRightWrapper
                    items={
                        <>
                            {isAuthorizedUser && (
                                <Styled.ButtonWrapper style={{ whiteSpace: 'nowrap' }} type='my-order'>
                                    <Link href={{ pathname: ROUTES.ORDERS, query: { from: 'menu' } }} passHref>
                                        <Styled.MyOrdersLink>{t('header_my_orders_link_title')}</Styled.MyOrdersLink>
                                    </Link>
                                </Styled.ButtonWrapper>
                            )}
                            {shouldShowWhatsAppNotifications && isAuthorizedUser && (
                                <Styled.ButtonWrapper ref={notificationWhatsAppRef} type='whatsApp'>
                                    <HeaderButton
                                        dir={dir}
                                        prefix={<NotificationOutline dir={dir} size={20} />}
                                        loading={notificationsWhatsAppEnable.isLoading}
                                        onClick={() => {
                                            dispatch(toggleNotificationWhatsApp());
                                        }}>
                                        <span>
                                            {notificationsWhatsAppEnable.data
                                                ? t('masters_notification_whatsapp_header_on')
                                                : t('masters_notification_whatsapp_header_off')}
                                        </span>
                                    </HeaderButton>
                                    <NotificationWhatsApp
                                        isOpen={isNotificationWhatsAppOpen}
                                        positionX={{ left: 'auto', right: '0' }}
                                        enable={notificationsWhatsAppEnable.data}
                                    />
                                </Styled.ButtonWrapper>
                            )}
                            {shouldShowBecomeSpecialistButton && (
                                <Styled.ButtonWrapper type='become_specialist'>
                                    <HeaderButton
                                        dir={dir}
                                        prefix={<Masters dir={dir} size={20} />}
                                        onClick={handleButtonClick}>
                                        <span>{t('header_become_a_specialist_button_title')}</span>
                                    </HeaderButton>
                                </Styled.ButtonWrapper>
                            )}
                            {shouldShowGeoButton && (
                                <Styled.ButtonWrapper type='geo'>
                                    <CitySwitcher
                                        dir={dir}
                                        orientation='bottom-end'
                                        offset={{ mainAxis: 8, crossAxis: 15 }}
                                        width='25rem'
                                        visibility={visibility}>
                                        <HeaderButton
                                            dir={dir}
                                            prefix={<PinOutline dir={dir} size={20} />}
                                            onClick={() => {
                                                if (!isGeoConfirmDialogOpen) {
                                                    visibility.toggle();
                                                }
                                            }}>
                                            <span>{pageCity.name}</span>
                                        </HeaderButton>
                                    </CitySwitcher>
                                    <GeoConfirmDialog isOpen={isGeoConfirmDialogOpen} onReject={visibility.setTrue} />
                                </Styled.ButtonWrapper>
                            )}
                            {shouldShowLanguageSwitcher && (
                                <Styled.ButtonWrapper type='language'>
                                    <DesktopLanguageSwitcher dir={dir} onChange={updateUserLang}>
                                        <HeaderButton
                                            dir={dir}
                                            prefix={<Earth dir={dir} size={20} />}
                                            aria-label={t('language_switcher_choose_language_text')}>
                                            {formatLocaleName(i18n.resolvedLanguage)}
                                        </HeaderButton>
                                    </DesktopLanguageSwitcher>
                                </Styled.ButtonWrapper>
                            )}
                            {isAuthorizedUser ? (
                                <Styled.ButtonWrapper type='login_logout'>
                                    <LogoutButton />
                                </Styled.ButtonWrapper>
                            ) : (
                                <Styled.ButtonWrapper type='login_logout'>
                                    <LoginButton />
                                </Styled.ButtonWrapper>
                            )}
                        </>
                    }
                    mobileHeaderMenu={mobileHeaderMenu}
                />
            ) : (
                mobileHeaderMenu
            )}
        </Styled.Header>
    );
};
