const LANGUAGE_NAMES: Record<string, string> = {
    ar: 'العربية',
    en: 'English',
    'es-MX': 'Español',
    'pt-BR': 'Português',
    ru: 'Русский',
    kk: 'Қазақша',
};

export const getLanguageName = (locale: string) => LANGUAGE_NAMES[locale];
