import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ROUTES } from 'common/constants';
import { ICityInfo, setCurrentCity, CitiesDropList, ICitiesDropListProps } from 'entities/city';

const STATIC_PAGES = new Set([ROUTES.ABOUT_US, ROUTES.CONTACTS, ROUTES.ORDERS]);

export const CitySwitcher = ({ onSelect, ...props }: ICitiesDropListProps) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleSelect = useCallback(
        async (cityInfo: ICityInfo) => {
            onSelect?.(cityInfo);
            // TODO: use INDIVIDUAL storage for such inputs
            if (!STATIC_PAGES.has(router.pathname)) {
                await router.push({
                    pathname: `/${cityInfo.citySlug}${router.pathname}`,
                    query: omit(router.query, 'citySlug'),
                });
            }
            dispatch(setCurrentCity(cityInfo));
        },
        [dispatch, onSelect, router],
    );

    return <CitiesDropList {...props} onSelect={handleSelect} />;
};
