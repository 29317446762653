export const getPrecalculatedWidths = (element: HTMLElement, moreWidthId: string) => {
    const containerRect = element.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const children = Array.from(element.children) as HTMLElement[];
    let moreWidth = 40;
    const necessaryWidths = children
        .reduce<number[]>((result, node) => {
            if (node.getAttribute('id') === moreWidthId) {
                moreWidth += node.getBoundingClientRect().width;
                return result;
            }
            const nodeRect = node.getBoundingClientRect();
            const nodeOffset =
                document.dir === 'ltr' ? nodeRect.left - containerRect.left : containerRect.right - nodeRect.right;
            return [...result, containerWidth - nodeOffset + 130];
        }, [])
        .reverse();

    return {
        necessaryWidths,
        containerWidth,
        moreWidth,
    };
};
