import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Enter } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ROUTES } from 'common/constants';
import { useBreakpoints } from 'common/lib';
import { HeaderButton } from 'common/ui';

interface IProps {
    desktopMode?: boolean;
}

export const LoginButton = ({ desktopMode }: IProps) => {
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const router = useRouter();
    const media = useBreakpoints();

    const handleUserLogin = React.useCallback(() => {
        void router.push({ pathname: ROUTES.AUTH });
    }, [router]);

    if (router.pathname === ROUTES.AUTH) {
        return null;
    }

    return !media.isGtS || desktopMode ? (
        <Cell
            dir={dir}
            size='s'
            prefix={<Enter dir={dir} size={24} color={variables['text-and-icon-secondary']} />}
            onClick={handleUserLogin}>
            {t('header_login_button_title')}
        </Cell>
    ) : (
        <HeaderButton dir={dir} prefix={<Enter dir={dir} size={20} />} onClick={handleUserLogin}>
            {t('header_login_button_title')}
        </HeaderButton>
    );
};
