import omit from 'lodash/omit';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@indriver/nova';
import { recordEventShort } from 'common/lib';
import { DropdownConfirm } from 'common/ui';
import { setCurrentCity, useCityRecommendation, usePageCityInfo } from 'entities/city';

interface IProps {
    isOpen: boolean;
    onReject: () => void;
}

export const GeoConfirmDialog = ({ isOpen, onReject }: IProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const router = useRouter();

    const { source, cityInfo } = usePageCityInfo(router.query.citySlug as string | undefined);
    const { data: cityRecommendation, isLoading } = useCityRecommendation(isOpen && source === 'geo_ip');

    useEffect(() => {
        if (cityRecommendation?.type) {
            // fixme: now it works for geo_ip only, shouldn't it work for all dialog displays?
            recordEventShort('masters_customer_geo_confirm_dialog_open_view', {
                type: cityRecommendation.type,
                city_id: cityRecommendation.cityInfo?.id,
            });
        }
    }, [cityRecommendation?.type]);

    const handleConfirmCityClick = () => {
        dispatch(setCurrentCity(cityInfo));
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        router.push({
            pathname: `/${cityInfo.citySlug}${router.pathname}`,
            query: omit(router.query, 'citySlug'),
        });
        recordEventShort('masters_customer_geo_confirm_dialog_click', { type: 'city_success_yes' });
    };

    const handleRejectCityClick = () => {
        onReject();
        recordEventShort('masters_customer_geo_confirm_dialog_click', {
            type: cityRecommendation?.type === 'city_not_found' ? 'city_not_found' : 'city_success_no',
        });
    };

    // when using fallback city or geo_ip is not successful, show only one button
    if (source === 'fallback' || (source === 'geo_ip' && cityRecommendation?.type !== 'city_success')) {
        return (
            <DropdownConfirm
                isOpen={isOpen}
                isLoading={isLoading}
                title={
                    cityRecommendation?.type === 'city_not_present'
                        ? t('geo_dialog_message__city_not_present')
                        : t('geo_dialog_message__city_not_found')
                }
                buttons={
                    <Button stretched size='s' onClick={handleRejectCityClick}>
                        {t('geo_dialog_choose_a_city_button')}
                    </Button>
                }
            />
        );
    }

    return (
        <DropdownConfirm
            isOpen={isOpen}
            title={t('geo_dialog_city_question_text', { city_name: cityInfo.name })}
            buttons={
                <>
                    <Button stretched size='s' onClick={handleConfirmCityClick}>
                        {t('common_yes_text')}
                    </Button>
                    <Button stretched size='s' design='secondary' onClick={handleRejectCityClick}>
                        {t('common_no_text')}
                    </Button>
                </>
            }
        />
    );
};
