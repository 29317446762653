import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import * as Styled from './header.styles';
import { getLastVisibleItem, getPrecalculatedWidths } from './lib';

interface IProps {
    items: React.ReactNode;
    mobileHeaderMenu: React.ReactElement;
}

interface IDimensions {
    necessaryWidths: number[];
    moreWidth: number;
}

export const HeaderRightWrapper = ({ items, mobileHeaderMenu }: IProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [lastVisibleMenuItem, setLastVisibleMenuItem] = useState(-1);
    const [dimensions, setDimensions] = useState<IDimensions>({
        necessaryWidths: [],
        moreWidth: 0,
    });
    const components = React.isValidElement(items)
        ? React.Children.toArray(items.props.children).filter(React.isValidElement)
        : [];

    const extraItems = components.slice(lastVisibleMenuItem + 1).map((item: React.ReactElement) => item.props.type);

    const updateLastVisibleItem = () => {
        if (!ref.current) {
            return;
        }

        const newIndex = getLastVisibleItem({
            containerWidth: ref.current.getBoundingClientRect().width,
            necessaryWidths: dimensions.necessaryWidths,
            moreWidth: dimensions.moreWidth,
        });
        setLastVisibleMenuItem(newIndex);
    };

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }
        const { necessaryWidths, containerWidth, moreWidth } = getPrecalculatedWidths(ref.current, 'mobileHeaderMenu');
        const itemIndex = getLastVisibleItem({
            containerWidth,
            necessaryWidths,
            moreWidth,
        });
        setDimensions({ moreWidth, necessaryWidths });
        setLastVisibleMenuItem(itemIndex);
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateLastVisibleItem);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        updateLastVisibleItem();
        window.addEventListener('resize', updateLastVisibleItem);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, dimensions]);

    const isMoreVisible = lastVisibleMenuItem < components.length - 1;

    return (
        <Styled.HeaderRightWrapper ref={ref}>
            {lastVisibleMenuItem === -1 ? (
                <>
                    {components}
                    {React.cloneElement(mobileHeaderMenu, { lastVisibleMenuItem })}
                </>
            ) : (
                <>
                    {components.slice(0, lastVisibleMenuItem + 1)}
                    {isMoreVisible &&
                        React.cloneElement(mobileHeaderMenu, {
                            desktopMode: true,
                            extraItems,
                        })}
                </>
            )}
        </Styled.HeaderRightWrapper>
    );
};
